<div class="background" [ngClass]="routeChecker() ? 'none-blur' : 'blur'"></div>
<nav class="navbar blur-none">
 <div class="logo" (click)="redirectTo('')"></div>
 <div class="navbuttons" *ngIf="routeChecker()">
  <div class="navbutton" *ngFor="let navbutton of NavButtons" routerLink="{{ navbutton.route }}">
   {{ navbutton.title }}
  </div>
  <div class="sign" (click)="redirectTo('login')">
   <span class="in">Sign in ></span>
  </div>
 </div>
</nav>
