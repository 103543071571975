<div class="topics-container">
 <div class="upload">
  <h4 class="title">Topics</h4>

  <div class="mat-elevation-z0 example-container">
   <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container [matColumnDef]="TrendingTopicsTableRowEnum.NAME">
     <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
     <td mat-cell *matCellDef="let element" class="w-40 pointer" (click)="emitSelectedTopic(element[TrendingTopicsTableRowEnum.NAME])">
      {{ element[TrendingTopicsTableRowEnum.NAME] }}
     </td>
    </ng-container>

    <ng-container [matColumnDef]="TrendingTopicsTableRowEnum.CURRENT_WEEK">
     <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-flex">Current week score</th>
     <td mat-cell *matCellDef="let element" class="column-text-align-center">
      {{ element[TrendingTopicsTableRowEnum.CURRENT_WEEK] }}
     </td>
    </ng-container>

    <ng-container matColumnDef="{{ TrendingTopicsTableRowEnum.CHANGE }}">
     <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-flex">Change</th>
     <td mat-cell *matCellDef="let element" class="column-text-align-center">
      <div class="grid-2-col">
       <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!element[TrendingTopicsTableRowEnum.CHANGE].split('').includes('-')">
        <path d="M1.66663 8.33366L8.33329 1.66699" stroke="#6FCF97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.66663 1.66699H8.33329V8.33366" stroke="#6FCF97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
       </svg>

       <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="element[TrendingTopicsTableRowEnum.CHANGE].split('').includes('-')">
        <path d="M1.66663 1.66699L8.33329 8.33366" stroke="#FE1102" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.33329 1.66699V8.33366H1.66663" stroke="#FE1102" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
       </svg>

       <!-- <span *ngIf="!element[TrendingTopicsTableRowEnum.CHANGE].split('').includes('-') && !element[TrendingTopicsTableRowEnum.CHANGE].split('').includes('+')"></span> -->
       <span> {{ element[TrendingTopicsTableRowEnum.CHANGE] | percent }}</span>
      </div>
     </td>
    </ng-container>

    <ng-container matColumnDef="{{ TrendingTopicsTableRowEnum.PREVIOUS_WEEK }}">
     <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-flex">Previous week score</th>
     <td mat-cell *matCellDef="let element" class="column-text-align-center">
      {{ element[TrendingTopicsTableRowEnum.PREVIOUS_WEEK] }}
     </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
   </table>

   <ng-template #noData>
    <p>No data found.</p>
   </ng-template>
  </div>
 </div>
</div>
