import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginPageRoutingModule } from './login-page-routing.module';
import { LoginPageComponent } from './login-page.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchasModule } from '../../shared/components/recaptcha/recaptcha.module';
import { LoginModule } from '../../shared/components/login/login.module';
import { RegisterModule } from '../../shared/components/register/register.module';
import { CoreModule } from '../../core/core.module';
import { LoadingModule } from '../../shared/components/loading/loading.module';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    CommonModule,
    LoginPageRoutingModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RecaptchasModule,
    LoginModule,
    RegisterModule,
    CoreModule,
    LoadingModule
  ]
})
export class LoginPageModule { }
