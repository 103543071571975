import {Component} from '@angular/core';
import {AuthService} from './shared/services/auth/auth.service';
import {SearchService} from './shared/services/search/search.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Identify';

  constructor(private auth: AuthService, private searchService: SearchService) {
    this.auth.isLoggedIn();
    this.auth.getToken();
  }
}
