<circle-progress
[titleColor]="'#fff'"
[showUnits]="false"
[showTitle]="true"
[toFixed]="1"
[imageHeight]="'113px'"
[imageWidth]="'113px'"
[percent]="id"
[radius]="100"
[maxPercent]="1000"
[titleFontSize]="'60px'"
[outerStrokeColor]="(id > 10 ? 'rgba(50, 253, 14, 0.9)' : id > 6 ? 'rgba(253, 209, 14, 0.9)' : 'rgba(200,0,0,0.7)')"
[innerStrokeColor]="(id > 10 ? 'rgba(50, 253, 14, 0.5)' : id > 6 ? 'rgba(253, 209, 14, 0.9)' : 'rgba(200,0,0,0.5)')"
[backgroundColor]="(id > 10 ? 'rgba(50, 253, 14, 0.2)' : id > 6 ? 'rgba(253, 209, 14, 0.5)' : 'rgba(200,0,0,0.3)')"
[animation]="true"
[animationDuration]="1"
></circle-progress>