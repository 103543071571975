import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsCircleComponent } from './results-circle.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
    declarations: [ResultsCircleComponent],
    imports: [
        CommonModule,
        NgCircleProgressModule.forRoot({
            'backgroundPadding': -4,
            'radius': 1,
            'toFixed': 1,
            'maxPercent': 10,
            // 'units': "percent",
            'unitsColor': "#483500",
            'outerStrokeWidth': 10,
            'titleColor': "#ffffff",
            'showSubtitle': false,
            'showUnits': false,
            'showInnerStroke': false,
            'responsive': true,
            'startFromZero': false
          }),
    ],
    exports: [
        ResultsCircleComponent
    ]
})
export class ResultsCircleModule { }