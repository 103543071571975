<div class="success" *ngIf="isSubmitted$.value === true">
 <div class="success-container">
  Search by intent keyword is necessary.
  <button class="button" (click)="handleSubmittedMessage()">Close</button>
 </div>
</div>

<div class="search-theme" [class.search-theme-results]="!(routeChecker$ | async)">
 <h2 class="title short-title" *ngIf="!(routeChecker$ | async)">Search</h2>

 <h2 class="title" *ngIf="routeChecker$ | async">Search organizations for your work</h2>

 <form class="search-form" [formGroup]="searchForm">
  <div class="radiobuttons">
   <mat-radio-group aria-label="Select an option" formControlName="searchByRadioButton">
    <mat-radio-button class="radiobutton" value="keyword" (click)="handleRadioButtonSwitch('keyword')" [checked]="true">Companies</mat-radio-button>
    <mat-radio-button class="radiobutton" value="jobTitle" (click)="handleRadioButtonSwitch('jobTitle')">Contacts</mat-radio-button>
   </mat-radio-group>
  </div>
  <mat-form-field appearance="outline">
   <mat-icon matPrefix>
    <img src="../../../../assets/icons/search.svg" alt="Search" class="icon" />
   </mat-icon>
   <mat-label class="grey">Search by intent keywords</mat-label>
   <input matInput type="text" (keydown.enter)="submit($event)" aria-label="text" placeholder="" formControlName="search_phrase" autocomplete="off" required #keyword />
  </mat-form-field>

  <span class="p-float-label p-float-label__country" *ngIf="routeChecker$ | async">
   <p-multiSelect
    (onPanelShow)="onPanelShow('countriesArrow')"
    (onPanelHide)="onPanelHide('countriesArrow')"
    [options]="countries"
    formControlName="country"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Country"
    [styleClass]="resultRoute ? 'countries-result' : 'countries-home'"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input">Country</label>
   <div class="many-selected" *ngIf="this.searchForm.get('country').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('country').value?.length }} Countries Selected</span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('country')"></span>
    </div>
   </div>
   <div class="arrow-filter-landing" [class.arrow-filter-landing__rotate]="countriesArrow" *ngIf="routeChecker$ | async"></div>
  </span>

  <span class="p-float-label p-float-label__country" *ngIf="!(routeChecker$ | async)">
   <p-multiSelect
    (onPanelShow)="onPanelShow('countriesArrow')"
    (onPanelHide)="onPanelHide('countriesArrow')"
    [options]="countries"
    formControlName="country"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Country"
    styleClass="countries-search"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input">Country</label>
   <div class="many-selected" *ngIf="this.searchForm.get('country').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('country').value?.length }} Countries selected </span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('country')"></span>
    </div>
   </div>
   <div class="arrow-filter" [class.arrow-filter__rotate]="countriesArrow" *ngIf="!(routeChecker$ | async)"></div>
  </span>

  <mat-form-field appearance="outline" *ngIf="!(routeChecker$ | async)">
   <mat-icon matPrefix>
    <img src="../../../../assets/icons/results-childs/town.svg" alt="City" class="icon" />
   </mat-icon>
   <mat-label>City</mat-label>
   <input type="text" placeholder="" aria-label="Text" matInput formControlName="city" autocomplete="off" [matAutocomplete]="city" />
   <mat-autocomplete #city="matAutocomplete" (opened)="onPanelShow('cityArrow')" (closed)="onPanelHide('cityArrow')">
    <mat-option *ngFor="let city of optionCities$ | async" [value]="city">{{ city }}</mat-option>
   </mat-autocomplete>
  </mat-form-field>
  <div class="arrow-filter" [class.arrow-filter__rotate]="countriesArrow" *ngIf="!(routeChecker$ | async)"></div>

  <span class="p-float-label p-float-label__industry" *ngIf="!(routeChecker$ | async)">
   <p-multiSelect
    (onPanelShow)="onPanelShow('industriesArrow')"
    (onPanelHide)="onPanelHide('industriesArrow')"
    [options]="industries"
    formControlName="company_industry"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Industry"
    styleClass="industry"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input">Industry</label>
   <div class="many-selected" *ngIf="this.searchForm.get('company_industry').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('company_industry').value?.length }} Items selected</span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('company_industry')"></span>
    </div>
   </div>
   <div class="arrow-filter" [class.arrow-filter__rotate]="industriesArrow"></div>
  </span>

  <span class="p-float-label" *ngIf="!(routeChecker$ | async)">
   <p-multiSelect
    (onPanelShow)="onPanelShow('revenuesArrow')"
    (onPanelHide)="onPanelHide('revenuesArrow')"
    [options]="revenues"
    formControlName="company_revenue"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Revenue"
    styleClass="revenue"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input"> Revenue </label>
   <div class="many-selected" *ngIf="this.searchForm.get('company_revenue').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('company_revenue').value?.length }} Items selected</span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('company_revenue')"></span>
    </div>
   </div>
   <div class="arrow-filter" [class.arrow-filter__rotate]="revenuesArrow"></div>
  </span>

  <span class="p-float-label" *ngIf="!(routeChecker$ | async)">
   <p-multiSelect
    (onPanelShow)="onPanelShow('companySizeArrow')"
    (onPanelHide)="onPanelHide('companySizeArrow')"
    [options]="companySize"
    formControlName="company_nb_employees"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Number of Employees"
    styleClass="company-size"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input">Number of Employees</label>
   <div class="many-selected" *ngIf="this.searchForm.get('company_nb_employees').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('company_nb_employees').value?.length }} Items selected </span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('company_nb_employees')"></span>
    </div>
   </div>
   <div class="arrow-filter" [class.arrow-filter__rotate]="companySizeArrow"></div>
  </span>

  <span class="p-float-label" *ngIf="!(routeChecker$ | async)">
   <p-multiSelect
    (onPanelShow)="onPanelShow('topicsArrow')"
    (onPanelHide)="onPanelHide('topicsArrow')"
    [options]="topics"
    formControlName="personae_topics"
    optionLabel="name"
    display="chip"
    [selectedItemsLabel]="'{0} items selected'"
    placeholder="Topics"
    styleClass="dartboard"
    [virtualScroll]="true"
    itemSize="30"
   >
   </p-multiSelect>
   <label for="float-input"> Topics </label>
   <div class="accordion"></div>
   <div class="many-selected" *ngIf="this.searchForm.get('personae_topics').value?.length > 1">
    <div class="how-many">
     <span>{{ searchForm.get('personae_topics').value?.length }} Topics selected</span>
     <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="clearFilter('personae_topics')"></span>
    </div>
   </div>
   <div class="arrow-filter" [class.arrow-filter__rotate]="topicsArrow"></div>
   <div class="select-box" *ngIf="this.searchForm.get('personae_topics').value?.length > 0">
    <ul role="listbox" *ngFor="let topic of searchForm.get('personae_topics')?.value" class="p-field-checkbox" style="list-style-type: none">
     <li role="listitem" class="select-box__dropdown">
      <span class="p-multiselect-token-icon pi pi-times-circle clickable" (click)="deleteFromTopics(topic.name)"></span>
      <label class="checkbox-label" [for]="topic.name">{{ topic.name }}</label>
     </li>
    </ul>
   </div>
  </span>
  <!-- 
  <mat-form-field appearance="outline" *ngIf="!(routeChecker$ | async)">
   <mat-icon matPrefix>
    <img src="../../../../assets/icons/results-childs/dartboard.svg" alt="Topics" class="icon" />
   </mat-icon>
   <mat-label>Topics</mat-label>
   <input matInput type="text" (keydown.enter)="submit($event)" aria-label="text" placeholder="" formControlName="personae_topics" autocomplete="on" />
  </mat-form-field> -->

  <span class="clear" (click)="clearFilters()" *ngIf="!(routeChecker$ | async)">x Clear filters</span>
  <app-recaptcha class="recaptcha" *ngIf="routeChecker$ | async" (isDisabledByReCaptcha)="handleReCaptcha(event)"></app-recaptcha>
  <button class="button" (click)="submit($event)" [disabled]="isDisabledByReCaptcha" *ngIf="routeChecker$ | async">Search</button>
  <button class="button" (click)="submit($event)" *ngIf="!(routeChecker$ | async)">Search</button>
 </form>
</div>
