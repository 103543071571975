import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { };

  excelPayload(query: string): {} {
    return {
      search_phrase: query,
      city: null,
      country: null,
      industry: null,
      number_of_employees: null,
      page_size: 1000,
      start_from: 0,
      include_fields: null,
      exclude_fields: null
    }
  }

  getExcelFile(payload) {
    return this.http.post<any>(`${environment.API_URL}?format=xlsx`, payload, {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events',
    }).subscribe(result => {
      if (result.type === HttpEventType.DownloadProgress) {
        this.isDisabled$.next(true);
      }
      if (result.type === HttpEventType.Response) {
        this.isDisabled$.next(false);
        const url = window.URL.createObjectURL(result.body);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'file.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    })
  }
}
