import { Component, Input, } from '@angular/core';

@Component({
  selector: 'app-results-circle',
  templateUrl: './results-circle.component.html',
  styleUrls: ['./results-circle.component.scss']
})
export class ResultsCircleComponent {

  @Input() id: number = 0
  
}
