<div class="user-information">
  <section class="box" *ngFor="let box of userInformationBoxes">
    <div class="price-box">
      <span class="price">Contact</span>
      <!-- <span class="price">${{ box.price }}</span> -->
      <!-- <span class="month">/month</span> -->
      <span class="month">us</span>

      <span class="title" [ngStyle]="{ color: box.color }">{{
        box.title
      }}</span>
    </div>

    <div class="describe">
      <div class="privilege" *ngFor="let privilege of box.describePrivileges">
        <div
          class="color-box"
          *ngIf="box.price === userInformationBoxes[0].price"
        >
          <img
            src="../../../../assets/icons/user-information/goldTick.svg"
            alt="o"
          />
        </div>
        <div
          class="color-box"
          *ngIf="box.price === userInformationBoxes[1].price"
        >
          <img
            src="../../../../assets/icons/user-information/blackTick.svg"
            alt="o"
          />
        </div>
        <div
          class="color-box"
          *ngIf="box.price === userInformationBoxes[2].price"
        >
          <img
            src="../../../../assets/icons/user-information/blueTick.svg"
            alt="o"
          />
        </div>
        {{ privilege }}
      </div>
    </div>

    <button
      class="button"
      *ngIf="box.price === userInformationBoxes[0].price"
      [disabled]="box.price === userInformationBoxes[0].price"
      [class.disabled]="box.price === userInformationBoxes[0].price"
      [class.currentPlan]="user.plan === 0"
    >
      {{ box.buttonMsg }}
    </button>
    <button
      class="button"
      *ngIf="box.price === userInformationBoxes[1].price"
      [class.basic]="box.price === userInformationBoxes[1].price"
      [class.currentPlan]="user.plan === 1"
    >
      {{ box.buttonMsg }}
    </button>
    <button
      class="button"
      *ngIf="box.price === userInformationBoxes[2].price"
      [class.professional]="box.price === userInformationBoxes[2].price"
      [class.currentPlan]="user.plan === 3"
    >
      {{ box.buttonMsg }}
    </button>
  </section>
</div>
