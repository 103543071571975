import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AwsProviderService } from '../../shared/services/aws-provider/aws-provider.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent {

  constructor(private aws: AwsProviderService) { }

  isLoading$: Observable<boolean> = this.aws.isLoading$.pipe(map(e => e))

  selectedTab: FormControl = new FormControl();
  // min. 6 znaków, w tym jeden capital i jeden alfanumeryczny
  tabs: string[] = ['Sign in', `Register`];

}
