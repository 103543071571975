import { Component, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecaptchaComponent implements OnInit {
  @Output() isDisabledByReCaptcha = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.isDisabledByReCaptcha.emit(false);
    } else {
      this.isDisabledByReCaptcha.emit(true);
    }
  }

  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }
}