  <app-core></app-core>
  <div class="container-desc">
    <div class="description">
      <h1 class="head text">Companies are waiting for you</h1>
      <p class="body text">
        Identify AI tool finds out the companies searching for products and
        services you offer within the area you define.
      </p>
      <ol class="list">
        <li class="li text">1. Introduce your query</li>
        <li class="li text">2. Narrow your search with the filters</li>
      </ol>
    </div>
    <div class="search">
        <div class="search-bar">
        <app-search></app-search>  
        </div>
    </div>
  </div>