import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { RecaptchasModule } from '../recaptcha/recaptcha.module';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
    declarations: [SearchComponent],
    imports: [
        FormsModule,
        CommonModule,
        MatRadioModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSliderModule,
        RecaptchasModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatChipsModule,
        MultiSelectModule,
        TooltipModule,
        CheckboxModule
    ],
    exports: [SearchComponent],
    providers: [
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: `#417CE1` }
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule { }
