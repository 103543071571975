import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AwsProviderService } from '../../shared/services/aws-provider/aws-provider.service';
import { SearchService } from '../../shared/services/search/search.service';

@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.scss'],
})
export class ResultsPageComponent implements OnInit {
  constructor(
    private route: Router,
    private authService: AuthService,
    private aws: AwsProviderService,
    private searchService: SearchService,
    private auth: AuthService,
  ) { }

  selectedTab: FormControl = new FormControl(0);
  tabs: string[] = ['Search', 'Trending topics', 'Data upload', 'User information'];
  user$: Observable<string> = this.auth.username$.pipe(take(2), map(e => e));
  isLoading$: Observable<boolean> = this.searchService.isLoading$.pipe(map(e => e))
  isAdmin$: Observable<boolean> = this.auth.isAdmin$.pipe(take(1), map(e => e))
  routeChecker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    val = null
  logout(): void {
    this.aws.logout().then(res => {
        this.authService.isLoggedIn$.next(false);
        this.searchService.data$.next('');
        this.searchService.isNotFound$.next(false);
        this.searchService.isDataShown$.next(true);
        this.searchService.isArrowShown$.next(false);
        this.route.navigate(['/'], { replaceUrl: true });
    })
  };

  handleSelectedTrendingTopic(value) {
    this.selectedTab.patchValue(0);
    this.searchService.search_phrase.next(value)
  }

  ngOnInit(): void {
    this.routeChecker();
  }

  routeChecker(): void {
    const url = this.route.url.split("/")[2]

    if (url === 'keyword' || url === 'jobtitle') {
      this.routeChecker$.next(true);
    } else {
      this.routeChecker$.next(false);
    }
  };

}
