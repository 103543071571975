import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AwsProviderService } from '../../services/aws-provider/aws-provider.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-reset-confirm',
  templateUrl: './reset-confirm.component.html',
  styleUrls: ['./reset-confirm.component.scss'],
})
export class ResetConfirmComponent {
  @Output() close = new EventEmitter<any>()
  @Input() case: number = null;

  animateDirection = false; // false - from top to mid, true - from mid to bottom
  error$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  message$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  resetFormState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // true - reset password with code, false - get code

  constructor(private formBuilder: FormBuilder, private aws: AwsProviderService) { }

  confirmForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    code: ['', Validators.required],
  });

  resetForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    code: ['', Validators.required],
    password1: ['', Validators.required],
    password2: ['', Validators.required],
  });

  handleResetFormState(bool: boolean): void {
    this.resetFormState$.next(bool);
    this.error$.next(null);
    this.message$.next(null);
  };

  getResetCode(): void {
    const username = this.resetForm.get('username').value;

    this.aws.getResetCode(username)
      .then(data => {
        this.error$.next(null);
        this.message$.next(data.CodeDeliveryDetails.Destination);
      })
      .catch(err => {
        this.message$.next(null);
        this.error$.next(err.message);
      });
  };

  resetPassword(): void {
    const username = this.resetForm.get('username').value;
    const code = this.resetForm.get('code').value;
    const password1 = this.resetForm.get('password1').value;
    const password2 = this.resetForm.get('password2').value;

    if (password1 !== password2) {
      this.error$.next("Passwords aren't that same.")

      setTimeout(() => {
        this.error$.next(null)
      }, 5000)
    }

    if (!this.resetForm.invalid && password1 === password2) {
      this.aws.resetPassword(username, code, password1)
        .then(res => this.error$.next('Success'))
        .catch (err => this.error$.next(err.message))
    }
  }

  confirmAccount(): void {
    if (!this.confirmForm.invalid) {
      const username = this.confirmForm.get('username').value;
      const code = this.confirmForm.get('code').value;

      this.aws.confirmSignUp(username, code)
        .then(res => {
          this.error$.next('Success');
        })
        .catch(err => {
          this.error$.next(err.message)
        })
    }
  }

  closeFrame() {
    this.animateDirection = true;

    setTimeout(() => {
      this.error$.next(null);
      this.close.emit(null)
    }, 1000)
  }

}
