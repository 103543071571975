import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import { delay, map, take } from 'rxjs/operators';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { ExcelService } from '../../services/excel/excel.service';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { PageEvent } from '@angular/material/paginator';
@Component({
 selector: 'app-results',
 templateUrl: './results.component.html',
 styleUrls: ['./results.component.scss'],
 animations: [
  trigger('FadeInRight', [transition(':enter', [animate('0.5s', keyframes([style({ opacity: '0', transform: 'translate3d(100%, 0, 0)' }), style({ opacity: '1', transform: 'translate3d(0, 0, 0)' })]))])]),
  trigger('FadeInLeft', [transition(':enter', [animate('0.5s', keyframes([style({ opacity: '0', transform: 'translate3d(-100%, 0, 0)' }), style({ opacity: '1', transform: 'translate3d(0, 0, 0)' })]))])])
 ]
})
export class ResultsComponent {
 ngOnInit(): void {
  this.routeChecker();
  this.searchService.isDataShown$.next(false);
  this.searchService.isNotFound$.next(false);
 }

 constructor(private route: Router, private searchService: SearchService, private excel: ExcelService) {}

 routeChecker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 isLoading$: Observable<boolean> = this.searchService.isLoading$.pipe(map((e) => e));
 isNotFound$: Observable<boolean> = this.searchService.isNotFound$.pipe(map((e) => e));
 isDataShown$: Observable<boolean> = this.searchService.isDataShown$.pipe(map((e) => e));
 isDisabled$: Observable<boolean> = this.excel.isDisabled$.pipe(map((e) => e));
 isArrowShown$: Observable<boolean> = this.searchService.isArrowShown$.pipe(map((e) => e));
 sortByScore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 sortByCompany$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 sortByPeople$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 sortByCountries$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 peopleNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

 companyNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 companyNumber: Observable<number> = this.companyNumber$.pipe(
  map((e) => e),
  delay(0)
 );

 countriesNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 countriesNumber: Observable<number> = this.countriesNumber$.pipe(
  map((e) => e),
  delay(0)
 );

 animationDirection = true; // true - fadeInRight, false - fadeInLeft
 length$ = this.searchService.totalLeads$;
 pageSize = 50;
 pageSizeOptions: number[] = [20, 50, 100];
 pageEvent: PageEvent;

 viewType: string = '';
 viewType$: Observable<string> = this.searchService.viewType$.pipe(
  map((viewTypeValue) => {
   this.resetSorts();
   this.animationDirection = true;
   this.searchService.isArrowShown$.next(false);
   this.searchService.isDataShown$.next(false);
   this.viewType = viewTypeValue;
   return viewTypeValue;
  })
 );

 pagianteDisplay({ pageIndex, pageSize }) {
   this.searchService.paginate(pageSize, pageIndex);
 }

 setPageSizeOptions(setPageSizeOptionsInput: string) {
  if (setPageSizeOptionsInput) {
   this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
  }
 }

 resetSorts(): void {
  this.sortByCountries$.next(false);
  this.sortByCompany$.next(false);
  this.sortByPeople$.next(false);
  this.sortByScore$.next(false);
 }

 ngOnDestroy(): void {
  this.searchService.isDataShown$.next(false);
  this.searchService.data$.next([]);
 }

 removeDuplicatesCountries(array): unknown[] {
  return [...new Set(array.map(({ _source }) => _source.Country))];
 }

 removeDuplicatesCompany(array): unknown[] {
  return [...new Set(array.map(({ _source }) => _source.Company_Name))];
 }

 removeDuplicatesPeople(array): unknown[] {
  return [...new Set(array.map(({ _source }) => `${_source.First_Name} ${_source.Last_Name}`))];
 }

 data$: Observable<any> = combineLatest([this.sortByCompany$.asObservable(), this.sortByCountries$.asObservable(), this.sortByPeople$.asObservable(), this.sortByScore$.asObservable(), this.searchService.data$.asObservable()]).pipe(
  map(([Company, Countries, People, Score, data]) => {
   if (data !== '' && !data.msg1) {
    this.companyNumber$.next(this.removeDuplicatesCompany(data).length);
    this.countriesNumber$.next(this.removeDuplicatesCountries(data).length);
    this.peopleNumber$.next(this.removeDuplicatesPeople(data).length);

    if (Score) {
     return data.sort((a, b) => b._score - a._score);
    }

    if (People) {
     return data.sort(this.sortPeople);
    }

    if (Countries) {
     return data.sort(this.sortCountries);
    }

    if (Company) {
     return data.sort(this.sortCompanyName);
    }

    return data;
   }

   return [data];
  })
 );

 getExcel(): void {
  this.excel.getExcelFile(this.searchService.searchForm$.value);
 }

 sortCompanyName(a, b): number {
  const bandA = a._source.Company_Name.toUpperCase();
  const bandB = b._source.Company_Name.toUpperCase();

  return bandA > bandB ? 1 : -1;
 }

 sortCountries(a, b): number {
  const bandA = a._source.Country.toUpperCase();
  const bandB = b._source.Country.toUpperCase();

  return bandA > bandB ? 1 : -1;
 }

 sortPeople(a, b): number {
  const bandA = a._source.First_Name.toUpperCase();
  const bandB = b._source.First_Name.toUpperCase();

  return bandA > bandB ? 1 : -1;
 }

 showContacts(companyName: string ): void {
  this.searchService.isDataShown$.next(false);
  this.searchService.isArrowShown$.next(true);
  this.searchService.isLoading$.next(true);
   const payload = companyName.split(' ').join('%20');

  this.searchService
   .getContacts(payload)
   .pipe(take(1))
   .subscribe(() => {
    this.animationDirection = true;
    this.viewType = 'jobTitle';
   });
 }

 backToCompaniesResults(): void {
  const { search_by, ...rest } = this.searchService.searchForm$.value;

  this.searchService.isDataShown$.next(false);
  this.searchService.isArrowShown$.next(false);
  this.searchService.isLoading$.next(true);

  this.searchService
   .getResults({
    search_by: 'companies',
    ...rest
   })
   .pipe(take(1))
   .subscribe(() => {
    this.animationDirection = false;
    this.viewType = 'keyword';
   });
 }

 redirectTo(): void {
  this.route.navigateByUrl('login', { skipLocationChange: true });
 }

 routeChecker(): void {
  const url = this.route.url.split('/')[2];

  if (url === 'keyword' || url === 'jobtitle') {
   this.routeChecker$.next(true);
  } else {
   this.routeChecker$.next(false);
  }
 }
}
