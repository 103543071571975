<div class="error" *ngIf="error$ | async">
  <div class="error-container">
    {{ error$ | async }}
    <button class="button" (click)="handleErrorMessage()">Close</button>
  </div>
</div>

<form class="search-form" [formGroup]="registerForm">
  <mat-form-field
    appearance="outline"
    class="register"
    [hideRequiredMarker]="true"
  >
    <input
      matInput
      type="text"
      (keydown.enter)="$event.preventDefault(); register()"
      aria-label="text"
      placeholder="Login*"
      formControlName="username"
      required
    />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="register"
    [hideRequiredMarker]="true"
  >
    <input
      matInput
      type="text"
      (keydown.enter)="$event.preventDefault(); register()"
      aria-label="text"
      placeholder="Email*"
      formControlName="email"
      required
    />
  </mat-form-field>
  <mat-form-field appearance="outline" class="register">
    <input
      matInput
      type="password"
      (keydown.enter)="$event.preventDefault(); register()"
      aria-label="text"
      placeholder="Password*"
      formControlName="password1"
      required
    />
  </mat-form-field>
  <mat-form-field appearance="outline" class="register">
    <input
      matInput
      type="password"
      (keydown.enter)="$event.preventDefault(); register()"
      aria-label="text"
      placeholder="Reenter password*"
      formControlName="password2"
      required
    />
  </mat-form-field>
</form>
<app-reset-confirm
  [case]="3"
  *ngIf="isConfirmWindow$ | async"
  (close)="handleConfirmWindow($event)"
></app-reset-confirm>

<app-recaptcha
  class="recaptcha"
  (isDisabledByReCaptcha)="handleReCaptcha($event)"
></app-recaptcha>
<span class="fill" *ngIf="error"
  >Boxes with "*" are obligatory</span
>
<button class="button" (click)="register()" [disabled]="isDisabledByReCaptcha">
  Sign up
</button>
