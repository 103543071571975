import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '../loading/loading.module';
import { TrendingTopicsComponent } from './trending-topics.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
    declarations: [TrendingTopicsComponent],
    imports: [
        LoadingModule,
        CommonModule,
        MatTableModule,
        MatSortModule,
    ],
    exports: [
        TrendingTopicsComponent
    ]
})

export class TrendingTopicsModule { }