import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsComponent } from './results.component';
import { MatIconModule } from '@angular/material/icon';
import { ResultsCircleModule } from '../results-circle/results-circle.module';
import { ResultsDetailModule } from '../results-detail/results-detail.module';
import { LoadingModule } from '../loading/loading.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [ResultsComponent],
    imports: [
        CommonModule,
        MatIconModule,
        ResultsCircleModule,
        ResultsDetailModule,
        LoadingModule,
        MatPaginatorModule
    ],
    exports: [ResultsComponent]
})

export class ResultsModule { }