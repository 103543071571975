import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  token$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  username$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoggedIn(): Promise<void> {
    return Auth.currentUserInfo().then((status) => {
      status ? this.isLoggedIn$.next(true) : this.isLoggedIn$.next(false);
      this.username$.next(status?.username);
    });
  }

  async getToken(): Promise<void> {
    this.token$.next((await Auth.currentSession()).getAccessToken().getJwtToken());
  }

  login({ username, password }): Promise<any> {
    return Auth.signIn({ username, password });
  }

  logout(): Promise<void> {
    return Auth.signOut({ global: true });
  }

  public resetTemporaryPassword(user, newPassword): Promise<void> {
    const { challengeParam: { userAttributes: { email } } } = user;

    return Auth.completeNewPassword(user, newPassword, { email });
  }

  public signUp({ username, password }): Promise<any> {
    return Auth.signUp({ username, password });
  }

  public confirmSignUp(username: string, code: string): Promise<void> {
    return Auth.confirmSignUp(username, code);
  }

  public getResetCode(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  public resetPassword(username: string, code: string, newPassword: string): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }
}
