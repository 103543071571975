import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import {AuthService} from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router, private auth: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    return Auth.currentUserInfo()
      .then(status => {
        if (status) {
          return true
        } else {
          this.route.navigate(['/login'], { replaceUrl: true })
        }
      })
      .catch(() => this.route.navigate(['/login'], { replaceUrl: true }))
  }
}
