import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DataUploadService} from '../../services/data-upload/data-upload.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SearchService} from '../../services/search/search.service';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent implements OnInit, OnDestroy {
  @ViewChild('upload', {static: true}) input: ElementRef;
  file$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  isCorrectType: boolean = null;
  isFileSizeCorrect: boolean = null;
  isSuccessfulUpload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  errors$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  savedRows$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  isLoading$: Observable<boolean> = this.searchService.isLoading$.pipe(map(e => e));
  documentsCounter$ = this.searchService.documentsCounter$.pipe(map(e => e));

  private inputHandler: () => void;

  constructor(private dataUpload: DataUploadService, private renderer2: Renderer2, private searchService: SearchService) {
  };

  ngOnInit(): void {
    this.searchService.getDocumentsCounter();

    const el = this.input.nativeElement;

    this.inputHandler = this.renderer2.listen(el, 'click', () => {
      this.renderer2.listen(el, 'change', () => {
        this.checkFile((<HTMLInputElement> el).files[0]);
        this.file$.next((<HTMLInputElement> el).files[0]);
      });
    });
  };

  ngOnDestroy(): void {
    this.inputHandler();
  }

  handleSuccessMessage(): void {
    this.isSuccessfulUpload$.next(null);
  }

  checkFile(file: File): void {
    const splitted = file.name.split('.').pop();

    this.isCorrectType = splitted === 'xlsx' || splitted === 'csv';
    this.isFileSizeCorrect = (file.size / 1024 / 1024) < 11;
  }

  filesDropped(file: File): void {
    this.checkFile(file);
    this.file$.next(file);
  };

  uploadFile() {
    if (this.file$.value) {
      const dataToUpload = new FormData();
      dataToUpload.append('file', this.file$.value);
      this.searchService.isLoading$.next(true);

      this.dataUpload.uploadExcelTemplate(dataToUpload)
        .pipe(
          catchError(async () => this.isSuccessfulUpload$.next(false))
        )
        .subscribe((res: any) => {
          this.searchService.isLoading$.next(false);

          if (res.validation_errors_count === 0) {
            this.isSuccessfulUpload$.next(true);
            return;
          }
          ;

          if (res.validation_errors_count > 0) {
            const errorMessageStructure = res.validation_errors.map((x) => {
              const id = x[0];
              const errorMessage: string = x[1];
              const columnTitle = errorMessage.split('\n')[1];
              const errorType = errorMessage.split('\n')[2].split(' ').slice(2, 4).join(' ');

              return columnTitle;
            });

            const errorsReduced = errorMessageStructure.reduce((prev, cur) => {
              prev[cur] = (prev[cur] || 0) + 1;
              return prev;
            }, {});

            this.savedRows$.next(res.success);
            this.errors$.next(Object.entries(errorsReduced));
            this.isSuccessfulUpload$.next(false);
          }
        });
    }
  };
}
