import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RecaptchasModule } from '../recaptcha/recaptcha.module';
import { ResetConfirmModule } from '../reset-confirm/reset-confirm.module';

@NgModule({
    declarations: [RegisterComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RecaptchasModule,
        ResetConfirmModule
    ],
    exports: [RegisterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class RegisterModule { }