import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataUploadService {

  constructor(private http: HttpClient) { }

  uploadExcelTemplate(file: FormData) {
    return this.http.post<any>(`${environment.API_UPLOAD}`, file)
  }
}
