import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrendingTopicsService {
  trendingTopics$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  getTrendingTopics(): Subscription {
    return this.http
      .get(`${environment.API_TRENDING_TOPICS}20`)
      .pipe(
        map(({hits}: any) => hits?.hits?.map(({_source}) => Object.assign(_source, {change: ((_source?.Current_week - _source?.Previous_week) / (_source?.Previous_week))?.toString()}))),
      )
      .subscribe((data) => {
        this.trendingTopics$.next(data);
      });
  }
}
