import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResultsPageRoutingModule } from './results-page-routing.module';
import { ResultsPageComponent } from './results-page.component';
import { CoreModule } from 'src/app/core/core.module';
import { ResultsModule } from 'src/app/shared/components/results/results.module';
import { SearchModule } from 'src/app/shared/components/search/search.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { DataUploadModule } from '../../shared/components/data-upload/data-upload.module';
import { UserInformationModule } from '../../shared/components/user-information/user-information.module';
import { LoadingModule } from '../../shared/components/loading/loading.module';
import { TrendingTopicsModule } from '../../shared/components/trending-topics/trending-topics.module';


@NgModule({
  declarations: [ResultsPageComponent],
  imports: [
    CommonModule,
    ResultsPageRoutingModule,
    CoreModule,
    SearchModule,
    ResultsModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    DataUploadModule,
    UserInformationModule,
    LoadingModule,
    TrendingTopicsModule
  ],
  exports: [ResultsPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResultsPageModule { }
