<div class="data-upload">
  <div class="upload">
    <h4 class="title">Upload your files</h4>
    <div class="drag-drop-input">
      <input id="data-upload" type="file" class="input" accept=".csv, .xlsx" #upload/>
      <label for="data-upload" class="inside-input">
        <div class="custom-input" appDrag (files)="filesDropped($event)">
          <img
            src="../../../../assets/icons/data-upload/data-upload.svg"
            class="icon"
            alt=""
          />
          <p class="drag-text">
            Drag and drop your files here or
            <span class="underline">browse</span> for a file to upload
          </p>
        </div>
      </label>
    </div>
    <button
      type="button"
      class="button"
      (click)="uploadFile()"
      [disabled]="!isCorrectType || !isFileSizeCorrect"
    >
      Upload
    </button>
    <ul class="errors-container" [class.d-grid]="(!isFileSizeCorrect && isFileSizeCorrect !== null) || (!isCorrectType && isCorrectType !== null) ? 'display: grid' : null">
      <li
        class="error"
        *ngIf="!isFileSizeCorrect && isFileSizeCorrect !== null"
      >
        File size is over 10MB.
      </li>
      <li class="error" *ngIf="!isCorrectType && isCorrectType !== null">
        Wrong extension of file.
      </li>
    </ul>
  </div>
  <article class="info">
    <!-- <div class="documents" *ngIf="!(routeChecker$ | async) && isAdmin$ | async"> -->
    <div class="documents" >
      <img class="documents-img" src="../../../../assets/documents.png" alt="" />
      <div class="documents-desc">
        <span class="documents-title">Leads in the database</span>
        <span class="documents-number">{{
          (documentsCounter$ | async | number).split(",").join(".")
        }}</span>
      </div>
    </div>
    <h3>Prepare your data for upload:</h3>
    <ol class="ordered">
      <li>
        download excel
        <a
          href="../../../../assets/upload-template/Upload_template.xlsx"
          class="underline white-text"
          download
          >template</a
        >
      </li>
      <li>prepare your xlsx file max 10MB</li>
    </ol>
  </article>
</div>
<app-loading [styleControl]="'data-upload'" *ngIf="isLoading$ | async"></app-loading>
<div class="success" *ngIf="isSuccessfulUpload$.value !== null">
  <div class="success-container" *ngIf="isSuccessfulUpload$.value === true">
    Successful file upload.
    <button class="button" (click)="handleSuccessMessage()">Close</button>
  </div>
  <div class="success-container" *ngIf="isSuccessfulUpload$.value === false">
    <span>Server saved {{ savedRows$ | async }} rows from file.</span>
    <div class="error-info" *ngFor="let error of errors$ | async">
      <span>Fields in column "{{ error[0] }}" are empty ( {{error[1]}} fields )</span>
    </div>
    <button class="button" (click)="handleSuccessMessage()">Close</button>
  </div>
</div>