import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, take} from 'rxjs/operators';
import {SearchService} from '../../services/search/search.service';
import {AuthService} from '../../services/auth/auth.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {
  @ViewChild('keyword', {static: true}) keyword: ElementRef;

  resultRoute = false;
  resultsRoute = false;

  countries;
  industries;
  revenues;
  companySize;
  topics;

  countriesArrow = false;
  industriesArrow = false;
  revenuesArrow = false;
  companySizeArrow = false;
  topicsArrow = false;
  cityArrow = false;
  searchForm: FormGroup;
  country = null;
  isDisabledByReCaptcha: boolean = true;
  isDisabledByEmptySearchPhrase$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isSubmitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  routeChecker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  optionCities$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isLoggedIn$: Observable<boolean> = this.auth.isLoggedIn$.pipe(map((e) => e));
  isAdmin$: Observable<boolean> = this.auth.isAdmin$.pipe(map((e) => e));

  constructor(private route: Router, private searchService: SearchService, private formBuilder: FormBuilder, private auth: AuthService) {
    this.ifResultRoute();
  }

  handleReCaptcha(boolean): void {
    this.isDisabledByReCaptcha = boolean;
  }

  deleteFromTopics(item: string) {
    this.searchForm.get('personae_topics').patchValue(this.searchForm.get('personae_topics').value.filter((el) => el.name !== item));
  }

  ngOnDestroy(): void {
    this.searchService.viewType$.next(this.searchForm.controls.searchByRadioButton.value);
  }

  ngOnInit(): void {
    this.auth.token$.pipe(take(1)).subscribe(e => {
      if (e?.length > 0) {
        this.auth.isLoggedIn()

        this.searchService.getTemplateData('Country')
          .pipe(take(1))
          .subscribe(e => this.searchService.optionCountries$.next(e));
        this.searchService.getTemplateData('Company_Industry')
          .subscribe(e => this.searchService.optionIndustry$.next(e));
        this.searchService.getTemplateData('Company_NB_Employees')
          .subscribe(e => this.searchService.optionNumberOfEmployees$.next(e));
        this.searchService.getTemplateData('Company_Revenue')
          .subscribe(e => this.searchService.optionRevenue$.next(e));
        this.searchService.getTemplateData('Topic_filter', 'topics')
          .subscribe(e => this.searchService.optionTopics$.next(e));

        this.searchService.optionCountries$.pipe(take(2)).subscribe(
          (e) =>
            (this.countries = e.map((el) => {
              return {name: el};
            }))
        );

        this.searchService.optionIndustry$.pipe(take(2)).subscribe(
          (e) =>
            (this.industries = e.map((el) => {
              return {name: el};
            }))
        );
        this.searchService.optionRevenue$.pipe(take(2)).subscribe(
          (e) =>
            (this.revenues = e.map((el) => {
              return {name: el};
            }))
        );
        this.searchService.optionNumberOfEmployees$.pipe(take(2)).subscribe(
          (e) =>
            (this.companySize = e.map((el) => {
              return {name: el};
            }))
        );
        this.searchService.optionTopics$.pipe(take(2)).subscribe((e) => {
          this.topics = e.map((e) => ({name: e}));
        });
      }
    });


    this.searchService.search_phrase.pipe(take(1)).subscribe(
      (el) =>
        (this.searchForm = this.formBuilder.group({
          searchByRadioButton: ['keyword'],
          search_phrase: [el ? el : ''],
          country: [null],
          city: [null],
          company_industry: [null],
          company_nb_employees: [null],
          company_revenue: [null],
          personae_topics: [null],
          page_size: 1000,
          start_from: 0
        }))
    );

    this.routeChecker();
    this.optionCities$.next(this.searchService.optionCities$.value);
    this.searchService.viewType$.next('keyword');

    this.searchForm.valueChanges
      .pipe(
        map(({searchByRadioButton, country, company_industry, company_nb_employees, company_revenue, personae_topics, ...rest}) => {
          const payload = {
            search_by: this.searchService.viewType$.value === 'keyword' ? 'company' : 'contacts',
            country: country ? country.map(({name}) => name).join(',') : null,
            company_industry: company_industry ? company_industry.map(({name}) => name).join(',') : null,
            company_nb_employees: company_nb_employees ? company_nb_employees.map(({name}) => name).join(',') : null,
            company_revenue: company_revenue ? company_revenue.map(({name}) => name).join(',') : null,
            personae_topics: personae_topics ? personae_topics.map(({name}) => name).join(',') : null
          };

          if (this.country !== payload.country) {
            this.country = payload.country;

            if (payload.country?.length > 0) {
              const payloadQuery = payload.country.split(',').join('%2C');
              this.searchService
                .getCities(payloadQuery)
                .pipe(take(1))
                .subscribe((e) => {
                  this.searchService.optionCities$.next(e.filter((el) => el.length > 2).sort());
                  this.optionCities$.next(this._filter('', this.searchService.optionCities$, 'city'));
                });
            } else {
              this.searchService.optionCities$.next([]);
            }
          }
          this.searchService.searchForm$.next(Object.assign(rest, payload));

          const {search_phrase, city} = rest;

          this.isDisabledByEmptySearchPhrase$.next(!search_phrase ? true : false);
          this.optionCities$.next(this._filter(city, this.searchService.optionCities$, 'city'));
        })
      )
      .subscribe((e) => e);

    this.searchForm.updateValueAndValidity();
    this.keyword.nativeElement.focus();
  }

  handleRadioButtonSwitch(value: string): void {
    this.searchService.viewType$.next(value);
    this.searchService.isNotFound$.next(false);
  }

  onPanelShow(filter: string) {
    if (filter === 'countriesArrow') {
      this.countriesArrow = true;
    }

    if (filter === 'industriesArrow') {
      this.industriesArrow = true;
    }

    if (filter === 'revenuesArrow') {
      this.revenuesArrow = true;
    }

    if (filter === 'topicsArrow') {
      this.topicsArrow = true;
    }

    if (filter === 'companySizeArrow') {
      this.companySizeArrow = true;
    }

    if (filter === 'cityArrow') {
      this.cityArrow = true;
    }
  }

  onPanelHide(filter: string) {
    if (filter === 'countriesArrow') {
      this.countriesArrow = false;
    }

    if (filter === 'industriesArrow') {
      this.industriesArrow = false;
    }

    if (filter === 'revenuesArrow') {
      this.revenuesArrow = false;
    }

    if (filter === 'topicsArrow') {
      this.topicsArrow = false;
    }

    if (filter === 'companySizeArrow') {
      this.companySizeArrow = false;
    }

    if (filter === 'cityArrow') {
      this.cityArrow = false;
    }
  }

  clearFilters(): void {
    this.searchService.viewType$.next('keyword');
    this.searchForm.setValue({
      searchByRadioButton: 'keyword',
      search_phrase: '',
      country: null,
      city: null,
      company_industry: null,
      company_nb_employees: null,
      company_revenue: null,
      personae_topics: null,
      page_size: 50,
      start_from: 0
    });
  }

  clearFilter(filter: string): void {
    this.searchService.viewType$.next(this.searchService.viewType$.value);
    this.searchForm.get(filter).patchValue(null);
  }

  handleSubmittedMessage(): void {
    this.isSubmitted$.next(false);
  }

  submit(event): void {
    event.preventDefault();

    if (this.isDisabledByEmptySearchPhrase$.value) {
      this.isSubmitted$.next(true);
      return;
    }

    this.isSubmitted$.next(null);
    this.isLoggedIn$
      .pipe(
        map((isLoggedIn) => {
          if (isLoggedIn) {
            this.searchService
              .getResults(this.searchService.searchForm$.value, 50, 0)
              .pipe(take(1))
              .subscribe((e) => e);
            this.searchService.viewType$.next(this.searchService.viewType$.value);
            this.redirectTo();
          }

          if (!isLoggedIn) {
            // 5 per page
            if (!this.isDisabledByReCaptcha) {
              const payload = Object.assign(this.searchService.searchForm$.value, {page_size: 5});
              this.searchService
                .getResults(payload, 5, 0)
                .pipe(take(1))
                .subscribe((e) => e);
              this.searchService.viewType$.next(this.searchService.viewType$.value);
              this.redirectTo();
            }
          }
        })
      )
      .pipe(take(1))
      .subscribe((e) => e);
  }

  _filter(value, options?: BehaviorSubject<string[]>, criteria?: string) {
    switch (criteria) {
      case 'city':
        const city = value ? value.toLowerCase() : '';
        return options.value.filter((option: any) => option.toLowerCase().includes(city)).sort();
    }
  }

  redirectTo(): void {
    if (this.routeChecker$.value) {
      this.route.navigateByUrl('result');
    }
  }

  routeChecker(): void {
    const url = this.route.url.split('/')[2];

    if (url === 'keyword' || url === 'jobtitle') {
      this.routeChecker$.next(false);
    } else {
      this.routeChecker$.next(true);
    }
  }

  ifResultRoute(): void {
    const url = this.route.url.includes('/result');

    this.resultRoute = url ? true : false;
  }

  ifResultsRoute(): void {
    const url = this.route.url.includes('/results');

    this.resultsRoute = url ? true : false;
  }
}
