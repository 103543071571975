import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetConfirmComponent } from './reset-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


@NgModule({
    declarations: [ResetConfirmComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    exports: [ResetConfirmComponent],
})

export class ResetConfirmModule { }