import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { TrendingTopicsTableRowEnum } from './enums/admin-panel-user-table-row.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TrendingTopicsService } from '../../services/trending-topics/trending-topics.service';
import { first, share, shareReplay, take } from 'rxjs/operators';

@Component({
 selector: 'app-trending-topics',
 templateUrl: './trending-topics.component.html',
 styleUrls: ['./trending-topics.component.scss']
})
export class TrendingTopicsComponent {
 public dataSource: MatTableDataSource<any>;
 public TrendingTopicsTableRowEnum: typeof TrendingTopicsTableRowEnum = TrendingTopicsTableRowEnum;
 @ViewChild(MatSort) sort: MatSort;
 @Output() selectedTrendingTopic = new EventEmitter();

 public displayedColumns: TrendingTopicsTableRowEnum[] = [TrendingTopicsTableRowEnum.NAME, TrendingTopicsTableRowEnum.CURRENT_WEEK, TrendingTopicsTableRowEnum.CHANGE, TrendingTopicsTableRowEnum.PREVIOUS_WEEK];

 updateTable() {
  this.trendingTopics.getTrendingTopics();
 }

 emitSelectedTopic(value) {
  this.selectedTrendingTopic.emit(value);
 }

 constructor(private router: Router, private trendingTopics: TrendingTopicsService) {
  this.updateTable();
  setTimeout(() => this.fillTableData(this.trendingTopics.trendingTopics$.value), 1000);
 }

 private fillTableData(data): void {
  this.dataSource = new MatTableDataSource(data);
  setTimeout(() => {
   this.dataSource.sort = this.sort;
  }, 1000);
 }
}
