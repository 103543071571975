import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
 providedIn: 'root'
})
export class SearchService {
 constructor(private http: HttpClient) {
 }


 documentsCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 isNotFound$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 isDataShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
 isArrowShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

 data$: BehaviorSubject<any> = new BehaviorSubject<any>('');
 dataStorage$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
 totalLeads$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 viewType$: BehaviorSubject<string> = new BehaviorSubject<string>('keyword');
 searchForm$: BehaviorSubject<any> = new BehaviorSubject<any>('');
 search_phrase: BehaviorSubject<any> = new BehaviorSubject<any>(null);

 optionCountries$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
 optionCities$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
 optionIndustry$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
 optionNumberOfEmployees$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
 optionRevenue$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
 optionTopics$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

 paginate(pageSize: number, pageIndex: number): void {
  const data = this.dataStorage$.value.slice(pageIndex === 0 ? 1 : pageIndex * pageSize, pageIndex === 0 ? 50 : pageIndex * pageSize + 50);
  this.data$.next(data);
 }

 getCities(query: string): Observable<any> {
  return this.http.get(`${environment.API_URL_CITY}?country=${query}`).pipe(
   take(1),
   catchError(async (e) => throwError(e))
  );
 }

 getTemplateData(value: string, settings?: string): Observable<any> {
  const url = settings ? `${environment.API_TEMPLATES_SETTINGS_VALUES}?field=${value}` : `${environment.API_TEMPLATES_VALUES}?field=${value}`;
  return this.http.get<any>(url)
 }

 getDocumentsCounter() {
  this.isLoading$.next(true);

  return (
   this.http
    .get(`${environment.API_DOCUMENTS_COUNT}`, )
    .pipe(take(1))
    .subscribe(({ count }: any) => {
     this.isLoading$.next(false);
     this.documentsCounter$.next(count);
    }),
   catchError((e) => {
    this.isLoading$.next(false);
    return throwError(e);
   })
  );
 }

 getResults(payload, pageSize?: number, page?: number) {
  this.isLoading$.next(true);

  return this.http.post<any>(`${environment.API_URL}`, payload).pipe(
   map((res: any) => {
    this.isDataShown$.next(true);
    this.isLoading$.next(false);

    const { hits } = res.hits;

    if (hits.length > 0) {
     this.isNotFound$.next(false);
     this.totalLeads$.next(hits.length);
     this.dataStorage$.next(hits);

     if (pageSize === 5) {
      this.data$.next(hits);
     } else {
      const data = hits.slice(0, 50);
      this.data$.next(data);
     }
    }

    if (hits.length === 0) {
     this.isDataShown$.next(false);
     this.isNotFound$.next(true);
     this.data$.next({
      msg1: `No results for ${payload.search_phrase}`,
      msg2: `Please try another query.`
     });
    }
   }),
   catchError(async (e) => {
    this.isLoading$.next(false);
    return throwError(e);
   })
  );
 }

 getContacts(payload, pageSize?: number, page?: number) {
  this.isLoading$.next(true);

  return this.http.get<any>(`${environment.API_URL_CONTACTS}?company_name=${payload}`).pipe(
   map((res: any) => {
    this.isDataShown$.next(true);
    this.isLoading$.next(false);

    const { hits } = res.hits;

    if (hits.length > 0) {
     this.isNotFound$.next(false);
     this.totalLeads$.next(hits.length);
     this.dataStorage$.next(hits);

     if (pageSize === 5) {
      this.data$.next(hits);
     } else {
      const data = hits.slice(0, 50);
      this.data$.next(data);
     }
    }

    if (hits.length === 0) {
     this.isDataShown$.next(false);
     this.isNotFound$.next(true);
     this.data$.next({
      msg1: `No results for ${payload.search_phrase}`,
      msg2: `Please try another query.`
     });
    }
   }),
   catchError(async (e) => {
    this.isLoading$.next(false);
    return throwError(e);
   })
  );
 }
}
