<div
  class="confirm"
  [class.animate_leave]="animateDirection ? true : false"
  [class.animate_enter]="animateDirection ? false : true"
  *ngIf="case === 1"
>

  <div class="confirm-box">
    <span class="font">Confirm account</span>
    <span class="font fz-s">Check your email for activation code</span>
    <span class="close" (click)="closeFrame()">X</span>
    <form class="search-form" [formGroup]="confirmForm">
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="text"
          (keydown.enter)="$event.preventDefault(); confirmAccount()"
          aria-label="text"
          placeholder="Username"
          formControlName="username"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="text"
          (keydown.enter)="$event.preventDefault(); confirmAccount()"
          aria-label="text"
          placeholder="Code"
          formControlName="code"
        />
      </mat-form-field>
    </form>
    <span class="error" [class.success]="error$.value === 'Success'" *ngIf="error$ | async">{{ error$ | async }}</span>
    <button class="button" (click)="confirmAccount()">Confirm</button>
  </div>
</div>


<div
  class="confirm"
  [class.animate_leave]="animateDirection ? true : false"
  [class.animate_enter]="animateDirection ? false : true"
  *ngIf="case === 2"
>

  <div class="confirm-box">
    <span class="font">Reset password</span>
    <!-- <span class="font fz-s">Check your email for activation code</span> -->
    <span class="close" (click)="closeFrame()">X</span>
    <form class="search-form" [formGroup]="resetForm" *ngIf="!(resetFormState$ | async)">
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="text"
          (keydown.enter)="$event.preventDefault(); getResetCode()"
          aria-label="text"
          placeholder="Username"
          formControlName="username"
        />
      </mat-form-field>
    </form>
    <form class="search-form" [formGroup]="resetForm" *ngIf="(resetFormState$ | async)">
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="text"
          (keydown.enter)="$event.preventDefault()"
          aria-label="text"
          placeholder="Username"
          formControlName="username"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="text"
          (keydown.enter)="$event.preventDefault()"
          aria-label="text"
          placeholder="Reset code"
          formControlName="code"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="password"
          (keydown.enter)="$event.preventDefault()"
          aria-label="text"
          placeholder="Password"
          formControlName="password1"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="login-input">
        <input
          matInput
          type="password"
          (keydown.enter)="$event.preventDefault()"
          aria-label="text"
          placeholder="Reenter password"
          formControlName="password2"
        />
      </mat-form-field>

    </form>
    <a class="forgot" (click)="handleResetFormState(true)" *ngIf="!(resetFormState$ | async)">I have reset code</a>
    <a class="forgot" (click)="handleResetFormState(false)" *ngIf="(resetFormState$ | async)">I don't have reset code</a>
    <span class="success" *ngIf="message$.value !== null">Reset code has been sent to {{ message$ | async }}. Please check your e-mail account.</span>
    <span class="error" [class.success]="error$.value === 'Success'" *ngIf="error$ | async">{{ error$ | async }}</span>
    <button class="button" (click)="getResetCode()" *ngIf="!(resetFormState$ | async)">Get reset code</button>
    <button class="button" (click)="resetPassword()" *ngIf="(resetFormState$ | async)">Reset password</button>
  </div>
</div>

<div
  class="confirm"
  [class.animate_leave]="animateDirection ? true : false"
  [class.animate_enter]="animateDirection ? false : true"
  *ngIf="case === 3"
>

  <div class="confirm-box">
    <span class="font success">Check your email for activation link</span>
    <span class="font fz-s success">1. Verify an account by click on activation link</span>
    <span class="font fz-s success">2. Login to application</span>

    <span class="error"*ngIf="error$ | async">{{ error$ | async }}</span>
    <button class="button" (click)="closeFrame()">Understood</button>
  </div>
</div>