import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AwsProviderService } from '../../services/aws-provider/aws-provider.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() tabControlValue: EventEmitter<number> = new EventEmitter<number>();
  error$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isConfirmWindow$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private formBuilder: FormBuilder, private aws: AwsProviderService, private authService: AuthService, private route: Router, private searchService: SearchService) { }

  ngOnInit(): void {
    this.aws.isLoading$.next(false);
    if (this.authService.isLoggedIn$.value) {
      this.redirectTo('/results/keyword');
    }
  }

  loginForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  handleConfirmWindow(value: string): void {
    this.isConfirmWindow$.next(value);
  }

  switchToRegister(): void {
    this.tabControlValue.emit(1);
  }

  signIn(event): void {
    event.preventDefault();

    if (!this.loginForm.invalid) {
      this.aws.isLoading$.next(true);
      this.aws
        .login(this.loginForm.value)
        .then((res) => {
          if (res) {
            this.authService.isLoggedIn$.next(true);
            this.authService.getToken();
            this.aws.isLoading$.next(false);
            this.redirectTo('/results/keyword');
          }
        })
        .catch((err) => {
          this.error$.next(err.message);
          this.aws.isLoading$.next(false);
        });
    }
  }

  redirectTo(value: string): void {
    this.route.navigate([`${value}`], { replaceUrl: true });
  }
}
