import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { ILogin } from '../../interfaces/ILogin.type';
@Injectable({
  providedIn: 'root'
})
export class AwsProviderService {

  constructor() { }

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public login(user: ILogin): Promise<any> {
    return Auth.signIn({
      username: user.username,
      password: user.password
    })
  };

  public logout(): Promise<any> {
    return Auth.signOut();
  };

  public signUp(user: ILogin): Promise<any> {
    return Auth.signUp({
      username: user.username,
      password: user.password
    })
  };

  public confirmSignUp(username: string, code: string): Promise<void> {
    return Auth.confirmSignUp(username, code);
  };

  public getResetCode(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  };

  public resetPassword(username: string, code: string, newPassword: string): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

}
