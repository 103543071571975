<section [ngClass]="(routeChecker$ | async) ? 'gridsome' : 'gridsome-percent'">
 <!-- <app-loading  *ngIf="isLoading$ | async"></app-loading> -->

 <div class="sort" *ngIf="viewType !== 'jobTitle' && (isDataShown$ | async)" [class.white-text]="routeChecker$ | async">
  Sort by:
  <div class="sort-score" (click)="resetSorts(); this.sortByScore$.next(true)">
   Score
   <img src="../../../../assets/icons/results-childs/sortarrows.svg" />
  </div>
  <div class="sort-size" (click)="resetSorts(); this.sortByCompany$.next(true)">
   Company
   <img src="../../../../assets/icons/results-childs/sortarrows.svg" />
  </div>
  <span class="excel" [class.white-text]="routeChecker$ | async" [class.disabled]="isDisabled$ | async" *ngIf="!(routeChecker$ | async)" (click)="getExcel()">
   <img src="../../../../assets/icons/excel/excelresult.svg" alt="" />
   Export to excel
  </span>
  <span class="excel" [class.white-text]="routeChecker$ | async" [class.disabled]="isDisabled$ | async" *ngIf="routeChecker$ | async" (click)="getExcel()">
   <img src="../../../../assets/icons/excel/excelresults.svg" alt="" />
   Export to excel
  </span>
 </div>
 <div class="sort" *ngIf="viewType === 'jobTitle' && (isDataShown$ | async)" [class.white-text]="routeChecker$ | async">
  <i class="arrow left" *ngIf="isArrowShown$ | async" (click)="backToCompaniesResults()"></i>
  Sort by:
  <div class="sort-score" (click)="resetSorts(); this.sortByPeople$.next(true)">
   People
   <img src="../../../../assets/icons/results-childs/sortarrows.svg" />
  </div>
  <div class="sort-size" (click)="resetSorts(); this.sortByCountries$.next(true)">
   Country
   <img src="../../../../assets/icons/results-childs/sortarrows.svg" />
  </div>
 </div>

 <div class="container-desc" *ngIf="!(isDataShown$ | async) && (routeChecker$ | async) && !(isLoading$ | async)">
  <div class="description" *ngIf="!(isNotFound$ | async)">
   <h1 class="head text">Instruction</h1>
   <p class="body text">Identify AI tool finds out the companies searching for products and services you offer within the area you define.</p>
   <ol class="list">
    <li class="li text">1. Introduce your query</li>
    <li class="li text">2. Narrow your search with the filters</li>
   </ol>
  </div>
  <div class="description" *ngIf="(isNotFound$ | async) && !(isLoading$ | async)">
   <div class="not-found" *ngFor="let data of data$ | async">
    <h1 class="head text">{{ data.msg1 }}</h1>
    <p class="body text">
     {{ data.msg2 }}
    </p>
   </div>
  </div>
 </div>

 <ul
  class="company-container animate__animated"
  [class.keyword]="routeChecker$ | async"
  [class.job-title]="viewType === 'jobTitle'"
  [ngStyle]="{
   animation: animationDirection ? 'fadeInRight 0.5s' : 'fadeInLeft 0.5s'
  }"
  *ngIf="isDataShown$ | async"
 >
  <li class="company-list" *ngFor="let data of data$ | async" [class.job-title]="viewType === 'jobTitle'" [class.contacts-result]="routeChecker$.value && viewType === 'jobTitle' ? false : true">
   <div class="score-table" *ngIf="viewType !== 'jobTitle'">
    <div class="score">
     <app-results-circle [id]="data._score"></app-results-circle>
     <span class="score-text">Score</span>
    </div>
   </div>
   <div class="description">
    <div class="title" [class.job]="viewType === 'jobTitle'">
     <p class="text-overflow m-b0" *ngIf="viewType !== 'jobTitle'">
      {{ data._source.Company_Name }}
     </p>
     <p class="text-overflow" *ngIf="viewType == 'jobTitle'">
      {{ data._source.First_Name }} {{ data._source.Last_Name }}
      <span class="text-overflow profession" *ngIf="viewType == 'jobTitle'">{{ data._source.Job_Title }}</span>
     </p>

     <div class="contacts" *ngIf="viewType !== 'jobTitle'" (click)="showContacts(data._source.Company_Name)">
      <img src="../../../../assets/icons/results-childs/employees.svg" alt="C" />
      Show contacts
     </div>
     <div class="score-table" *ngIf="viewType === 'jobTitle'">
      <div class="score">
       <app-results-circle [id]="data._score"></app-results-circle>
      </div>
     </div>
    </div>
    <div class="details" *ngIf="viewType !== 'jobTitle'" [class.details-grid]="viewType !== 'jobTitle'">
     <app-results-detail [data]="[data._source.Company_Industry]" [title]="'Industry'" [icon]="'../../../../assets/icons/results-childs/industry.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Address1, data._source.Address2]" [title]="'Address'" [icon]="'../../../../assets/icons/results-childs/location-result.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Company_HQ_Phone]" [title]="'Phone'" [icon]="'../../../../assets/icons/results-childs/phone.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.City, data._source.State, data._source.Postal_Code]" [title]="'City'" [icon]="'../../../../assets/icons/results-childs/city.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Company_Website]" [title]="'Website'" [icon]="'../../../../assets/icons/results-childs/website.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Country]" [title]="'Country'" [icon]="'../../../../assets/icons/results-childs/country.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Company_Revenue]" [title]="'Revenue'" [icon]="'../../../../assets/icons/results-childs/revenue.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Intent, data._source.Intent_URLs]" [title]="'Intent'" [icon]="'../../../../assets/icons/results-childs/intent.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Company_NB_Employees]" [title]="'Number of Employees'" [icon]="'../../../../assets/icons/results-childs/NbOfEmployees.svg'"></app-results-detail>
     <app-results-detail [data]="[null]" [title]="null" [icon]="null"></app-results-detail>
     <app-results-detail class="topics" [data]="[data._source.Topics]" [title]="'Topics'" [icon]="'../../../../assets/icons/results-childs/topics.svg'"></app-results-detail>
    </div>
    <div class="details" *ngIf="viewType === 'jobTitle'">
     <app-results-detail [data]="[data._source.Email]" [title]="'Email'" [icon]="'../../../../assets/icons/results-childs/email.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Phone]" [title]="'Phone'" [icon]="'../../../../assets/icons/results-childs/phone.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.LinkedIn]" [title]="'LinkedIn'" [icon]="'../../../../assets/icons/results-childs/linkedin.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Address]" [title]="'Address'" [icon]="'../../../../assets/icons/results-childs/location-result.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.City, data._source.State, data._source.Postal_Code]" [title]="'City'" [icon]="'../../../../assets/icons/results-childs/city.svg'"></app-results-detail>
     <app-results-detail [data]="[data._source.Country]" [title]="'Country'" [icon]="'../../../../assets/icons/results-childs/country.svg'"></app-results-detail>
    </div>
   </div>
  </li>
 </ul>
 <button class="subscribe" (click)="redirectTo()" *ngIf="!(routeChecker$ | async)">Subscribe for more</button>
 <div class="paginator" *ngIf="(routeChecker$ | async) && (isDataShown$ | async)">
  <mat-paginator [length]="length$ | async" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pagianteDisplay($event)" aria-label="Select page"> </mat-paginator>
 </div>
</section>
<div class="container-desc" *ngIf="data$ | async">
 <app-loading *ngIf="isLoading$ | async"></app-loading>
 <div class="description" *ngIf="!(isDataShown$ | async) && (data$ | async).msg1"></div>
</div>
