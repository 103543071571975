// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 production: false,
 API_URL: `https://identify.tdsapis.com/identify/search_v2`,
 API_TRENDING_TOPICS: `https://identify.tdsapis.com/identify/documents/trending-topics/?top_n_topics=`,
 API_URL_CONTACTS: `https://identify.tdsapis.com/identify/documents/contacts`,
 API_URL_CITY: `https://identify.tdsapis.com/identify/filters/city`,
 API_TEMPLATES_VALUES: `https://identify.tdsapis.com/identify/documents/distinct/`,
 API_TEMPLATES_SETTINGS_VALUES: `https://identify.tdsapis.com/identify/documents/distinct-settings/`,
 API_UPLOAD: `https://identify.dtds-ai.com/identify/documents/uploadfile/`,
 API_DOCUMENTS_COUNT: `https://identify.tdsapis.com/identify/documents/count`
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
