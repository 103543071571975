import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavButtons } from '../shared/interfaces/NavButtons.type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreComponent {

  constructor(private route: Router) {}

  NavButtons: NavButtons[] = [
    {
      title: "Home",
      toRoute: "",
  },
  {
      title: "About",
      toRoute: "about",
  },
  {
      title: "Pricing",
      toRoute: "pricing",
  },
  {
      title: "Contact us",
      toRoute: "contact",
  },
  ]

  routeChecker(): boolean {
    const url = this.route.url.split("/")[1];
    
    if (url === 'result' || url === 'login' || url === 'results') {
      return false
    } else {
      return true
    }
  }

  redirectTo(value: string): void {
    this.route.navigateByUrl(`${value}`)
  }
}
