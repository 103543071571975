<app-core *ngIf="!(routeChecker$ | async)"></app-core>
<section class="results" *ngIf="!(routeChecker$ | async)">
 <app-search class="search-component p-rel o-border"></app-search>
 <app-results class="results-component p-rel o-border"></app-results>
</section>
<div class="user" *ngIf="routeChecker$ | async">
 <div class="icon">
  <img src="../../../assets/icons/results-childs/login.svg" alt="" />
 </div>
 <p class="logout">{{ user$ | async }}</p>
 <div class="sign" (click)="logout()">
  <div class="logout-icon">
   <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
     fill="currentColor"
     d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
    ></path>
   </svg>
  </div>
 </div>
</div>

<section class="results-keyword" *ngIf="routeChecker$ | async">
 <app-search class="search-component p-rel o-border animate__animated fadeInLeft" *ngIf="selectedTab.value === 0"></app-search>
 <mat-tab-group class="switch" [color]="'warn'" [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
  <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab" class="tab">
   <app-results class="search-component p-rel o-border" *ngIf="selectedTab.value === 0"></app-results>
   <app-trending-topics *ngIf="selectedTab.value === 1" (selectedTrendingTopic)="handleSelectedTrendingTopic($event)"></app-trending-topics>
   <app-data-upload *ngIf="selectedTab.value === 2"></app-data-upload>
   <app-user-information *ngIf="selectedTab.value === 3"></app-user-information>
  </mat-tab>
 </mat-tab-group>
 <div class="background"></div>
</section>
