import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { SearchService } from '../../services/search/search.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results-detail',
  templateUrl: './results-detail.component.html',
  styleUrls: ['./results-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsDetailComponent {

  constructor(private searchService: SearchService, private route: Router){}
  
  viewType: string = '';
  viewType$: Observable<string> = this.searchService.viewType$.pipe(map(e => this.viewType = e));
  isArrowShown$: Observable<boolean> = this.searchService.isArrowShown$.pipe(map(e => e));

  @Input() data: any;
  @Input() title: string;
  @Input() icon: string;

  routeChecker(): boolean {
    const url = this.route.url.split("/")[2]

    if (url === 'keyword' || url === 'jobtitle') {
      return false
    } else {
      return true
    }
  };
}
