import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataUploadComponent } from './data-upload.component';
import { DragDirective } from '../../directives/appDrag';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [DataUploadComponent, DragDirective],
    imports: [
        LoadingModule,
        CommonModule,
    ],
    exports: [
        DataUploadComponent
    ]
})

export class DataUploadModule { }