import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { AwsProviderService } from '../../services/aws-provider/aws-provider.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  constructor(private formBuilder: FormBuilder, private aws: AwsProviderService) { };

  isDisabledByReCaptcha: boolean = true;
  error$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isConfirmWindow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  error: boolean = false;


  handleReCaptcha(boolean): void {
    this.isDisabledByReCaptcha = boolean
  }

  handleErrorMessage(): void {
    this.error$.next(null);
  }

  handleConfirmWindow(value: boolean): void {
    this.isConfirmWindow$.next(value);
  }

  registerForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', Validators.required],
    password1: ['', [Validators.required, Validators.minLength(6)]],
    password2: ['', [Validators.required, Validators.minLength(6)]],
  });

  email() { return this.registerForm.get('email').value };
  username() { return this.registerForm.get('username').value };
  password1() { return this.registerForm.get('password1').value };
  password2() { return this.registerForm.get('password2').value };

  async register() {
    const username = this.username();
    const password1 = this.password1();
    const password2 = this.password2();
    const email = this.email();
    this.error = false;

    if (this.registerForm.invalid) {
      this.error = true;
      return
    }

    if (password1 !== password2) {
      this.error$.next("Passwords aren't that same.")

      setTimeout(() => {
        this.error$.next(null)
      }, 5000)
    }

    if (!this.registerForm.invalid && password1 === password2) {
      this.aws.isLoading$.next(true);

      await Auth.signUp({ username, password: password1, attributes: { email } })
        .then(res => {
          this.aws.isLoading$.next(false);
          this.isConfirmWindow$.next(true);
        })
        .catch(error => {
          this.aws.isLoading$.next(false);
          this.error$.next(error.message);
        })
    }
  }
}
