<form class="search-form" [formGroup]="loginForm">
    <mat-form-field appearance="outline" class="login-input">
        <input matInput type="text" (keydown.enter)="signIn($event)" aria-label="text" placeholder="Username" formControlName="username" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="login-input">
        <input matInput type="password" (keydown.enter)="signIn($event)" aria-label="text" placeholder="Password" formControlName="password" />
    </mat-form-field>
</form>
<span class="error" *ngIf="error$ | async"
    >{{ error$ | async }}
    <button (click)="handleConfirmWindow('Confirm')" *ngIf="error$.value === 'User is not confirmed.'">Confirm</button>
</span>
<app-reset-confirm [case]="1" *ngIf="isConfirmWindow$.value === 'Confirm'" (close)="handleConfirmWindow($event)"></app-reset-confirm>
<span class="error" *ngIf="loginForm.invalid && !loginForm.pristine">Fill in the missing fields</span>
<a class="forgot" (click)="handleConfirmWindow('Forgot')">Forgot your password?</a>
<app-reset-confirm [case]="2" *ngIf="isConfirmWindow$.value === 'Forgot'" (close)="handleConfirmWindow($event)"></app-reset-confirm>
<button class="button" (click)="signIn($event)">Sign in</button>
<p class="new-account">
    Don't have an account?
    <a (click)="switchToRegister()" class="signup"> Sign up</a>
</p>
