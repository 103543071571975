import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsDetailComponent } from './results-detail.component';


@NgModule({
    declarations: [ResultsDetailComponent],
    imports: [
        CommonModule,
    ],
    exports: [ResultsDetailComponent]
})

export class ResultsDetailModule { }