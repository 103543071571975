import { Component } from '@angular/core';
import { UserInformation } from '../../interfaces/UserInformation.type';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent {

  constructor() { }

  user = {
    plan: 0
  };

  userInformationBoxes: UserInformation[] = [
    {
      price: 50,
      title: "Basic",
      describePrivileges: [
        "Up to 5 queries",
        "5 results per query",
        "Company name",
        "Company address",
        "2 Active filters: Country & industry"
      ],
      buttonMsg: "Your current plan",
      color: "#F3B10E"
    },
    {
      price: 100,
      title: "Professional",
      describePrivileges: [
        "Up to 100 queries",
        "Complete list of results",
        "Company names",
        "Company address",
        "Contant email & phone number",
        "5 Active filters: country, city, industry, company size, topic",
        "Data upload to Excel"
      ],
      buttonMsg: "Upgrade to Professional",
      color: "#000000"
    },
    {
      price: 199,
      title: "Enterprise",
      describePrivileges: [
        "Unlimited search queries",
        "Complete list of results",
        "Company names",
        "Company address",
        "Contact email & phone number",
        "5 Active filters: country, city, industry, company size, topic",
        "Data upload to Excel",
        "Contact employees email & phone number",
        "Contact employees professional media profile"
      ],
      buttonMsg: "Upgrade to Enterprise",
      color: "#417CE1"
    },
  ]


}
