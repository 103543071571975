import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() styleControl?: string = null;

  routeChecker$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.routeChecker();
  }

  routeChecker(): void {
    const url = this.route.url.split("/")

    if (url[1] === 'result') {
      this.routeChecker$.next('result');
    }

    if (url[2] === 'keyword') {
      this.routeChecker$.next('keyword');
    }

    if (url[1] === 'login' || this.styleControl === 'data-upload') {
      this.routeChecker$.next('login')
    }
  };
}
