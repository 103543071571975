<p
    class="detail"
    [class.detailResult]="routeChecker()"
    [class.detail-topics]="title === 'Topics'"
    [class.detail-topicsResult]="routeChecker() && title === 'Topics'"
    [class.contacts]="viewType === 'jobTitle' || (isArrowShown$ | async)"
    *ngIf="viewType$ | async"
>
    <img class="icon" [src]="icon" alt="{{ title ? title : null }}" [class.topics-icon]="title === 'Topics'" />

    <span *ngIf="title" [class.my1vh]="title === 'Topics'" title="{{ title }}">{{ title }}:</span>

    <span class="wrap-text" *ngIf="title !== 'Topics' && title !== 'Website' && title !== 'LinkedIn'" title="{{ data[0] }} {{ data[1] }} {{ data[2] }}">
        {{ data[0] }} {{ data[1] }} {{ data[2] }}
    </span>

    <a class="wrap-text" *ngIf="title === 'Website' || title === 'LinkedIn'" href="http://{{ data }}" target="_blank">
        {{ data }}
    </a>

    <span class="chips-container" *ngIf="title === 'Topics'">
        <div class="chips" *ngFor="let item of data[0]?.split(',')">{{ item }}</div>
    </span>
</p>
