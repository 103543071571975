<div class="login-page-theme">
  <app-core></app-core>
  <div class="login">
    <app-loading *ngIf="isLoading$ | async"></app-loading>
    <mat-tab-group
      class="switch"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="selectedTab.setValue($event)"
    >
      <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
        <app-login
          *ngIf="index === 0"
          (tabControlValue)="selectedTab.setValue($event)"
        ></app-login>
        <app-register *ngIf="index === 1"></app-register>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
